.view.search {
  overflow: hidden;
}

.view__search {
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  height: inherit;
}

.search__input-cont {
  position: absolute;
  height: 70px;
  width: 100%;
  background-color: black;
}

.search__input {
  width: 100%;
  height: 50px;
  padding: 5px;
  margin: 10px auto;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 20px;
}

.search__results {
  padding: 70px 0 0 0;
  overflow-y: scroll;
  height: 83%;
}

.search__row-cont {
  display: flex;
  margin-bottom: 15px;
}

.search__artwork {
  max-width: 100px;
}

.search__title-cont {
  align-self: center;
  margin: 0 10px;
}

.search__favorite {
  align-content: center;
  right: 10%;
  position: relative;
  margin-left: auto;
  font-size: 30px;
}
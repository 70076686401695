.library__title {
  text-align: center;
  font-size: 40px;
  margin: 0 0 15px 0;
}

.library__favorite {
  align-content: center;
  right: 10%;
  position: relative;
  margin-left: auto;
  font-size: 30px;
}

.library__subtitle {
  text-align: center;
  margin: 50px auto;
  font-size: 20px;
}
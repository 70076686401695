.player {
  height: 60px;
  border: 1px solid white;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px 5px 10px;
  font-size: 30px;
  background-color: #000;
}

.player__album-cont {
  margin-right: 15px;
  display: flex;
}

.player__album {
  max-height: 50px;
}

.player__now-playing-cont {
  margin-right: auto;
}

.player__now-playing-artist, .player__now-playing-title {
  margin: 0;
  padding: 0px;
  font-size: 14px;
}

.player__now-playing-title {
  font-weight: 800;
}

.player__favorite {
  margin: 0 4%;
}

.player__icon-cont {
  width: 24px;
}
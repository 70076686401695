.footer {
  height: 60px;
  border: 1px solid white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.footer__icon-cont {
  color: #fff;
  font-size: 28px;
  margin: 0px 10px;
  text-align: center;
}

.footer__icon-cont p {
  font-size: 18px;
  margin: 0;
}
.track-duration {
  width: 15vw;
  position: relative;
  padding: 0 5%;
  margin-right: auto;
}

.track-duration__bar {
  width: 100%;
  height: 4px;
  border-color: #ffffff;
  background-color: #ffffff;
  position: absolute;
  left: 5%;
}

.track-duration__base {
  opacity: 50%;
  margin: auto;
}